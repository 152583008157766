<template>
  <div class="main">
    <!-- 面包屑导航 -->
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item >首页</el-breadcrumb-item>
      <el-breadcrumb-item>产品生产信息</el-breadcrumb-item>
      <el-breadcrumb-item>上传产品生产信息</el-breadcrumb-item>
    </el-breadcrumb> -->
    <!-- 卡片视图区 -->
    <el-card class="box-card">
      <!-- 搜索与添加区域 -->
      <el-row :gutter="10">
        <el-col :span="6">
          <el-input
            clearable
            v-model="queryInfo.query"
            @clear="getDataList"
            placeholder="请输入品种名称"
            class="input-with-select"
          >
            <el-button
              @click="getDataList"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加品种信息</el-button
          >
        </el-col>
        <el-col :span="5">
          <i class="el-icon-warning" style="color: #ccc000; line-height: 35px"
            >提示：双击条目进行编辑</i
          >
        </el-col>
      </el-row>
      <!-- 数据列表区域 -->
      <el-table
        v-loading="loading"
        :data="tableData"
        @row-dblclick="edit"
        style="width: 100%"
      >
        <el-table-column label="编号" type="index"></el-table-column>
        <el-table-column label="作物" prop="j_name"></el-table-column>
        <el-table-column label="品种名称" prop="j_mingcheng"></el-table-column>
        <el-table-column label="审定日期" prop="j_sdsj"></el-table-column>
        <el-table-column label="种类" prop="j_zhonglei"></el-table-column>
        <el-table-column label="审定编号" prop="j_sdbh"></el-table-column>
        <el-table-column label="生产商名称" prop="j_scsmc"></el-table-column>
        <el-table-column label="选育单位" prop="j_xydw"></el-table-column>
        <el-table-column
          label="推广区域"
          :show-overflow-tooltip="true"
          prop="j_sytgqy"
        ></el-table-column>
        <el-table-column
          label="特征特性"
          :show-overflow-tooltip="true"
          prop="j_tztx"
        ></el-table-column>
        <el-table-column label="转基因" prop="j_jfzjy"></el-table-column>
        <!-- <el-table-column label="品种简介" prop="content"></el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="管理" placement="top-start">
              <el-button
                @click.stop="getInfo(scope.row)"
                type="primary"
                icon="el-icon-s-tools"
              ></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top-end">
              <el-button
                type="warning"
                @click.stop="delInfo(scope.row.id)"
                icon="el-icon-delete"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        background
        layout="prev, pager, next,total"
        :total="total"
        :page-size="queryInfo.pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <!-- 新增产品生产dialog -->
      <el-drawer
        title="添加品种信息"
        :visible.sync="addDialogVisible"
        @closed="closedialog"
      >
        <el-form
          size="medium"
          ref="addFormRef"
          :model="addFormInfo"
          label-width="120px"
        >
          <el-form-item label="作物">
            <el-cascader
              v-model="addFormInfo.j_sort_path"
              :options="sort"
              :props="{
                expandTrigger: 'hover',
                value: 'j_path',
                label: 'j_name',
              }"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="种子分类">
            <el-cascader
              v-model="addFormInfo.j_zhonglei"
              :options="ZhongZiclass"
              :props="{
                expandTrigger: 'hover',
                value: 'j_name',
                label: 'j_name',
              }"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="种子分类">
            <el-select v-model="addFormInfo.j_zhonglei2" placeholder="请选择">
              <el-option label="原种" value="原种"></el-option>
              <el-option label="大田用种" value="大田用种"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="品种名称" prop="scz">
            <el-input
              v-model="addFormInfo.j_mingcheng"
              placeholder="例如：华科玉338"
            ></el-input>
          </el-form-item>
          <el-form-item label="推广区域" prop="scz">
            <el-input
              v-model="addFormInfo.j_sytgqy"
              placeholder="例如：河北"
            ></el-input>
          </el-form-item>

          <el-form-item label="审定时间" prop="year">
            <el-date-picker
              value-format="yyyy-M"
              format="yyyy-M"
              v-model="addFormInfo.j_sdsj"
              type="month"
              placeholder="选择年份"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="审定编号" prop="class">
            <el-input
              v-model="addFormInfo.j_sdbh"
              placeholder="例如：玉米"
            ></el-input>
          </el-form-item>
          <el-form-item label="选育单位" prop="company">
            <el-input
              v-model="addFormInfo.j_xydw"
              placeholder="例如：河北XXXX有限公司"
            ></el-input>
          </el-form-item>
          <el-form-item label="生产商名称" prop="company">
            <el-input
              v-model="addFormInfo.j_scsmc"
              placeholder="例如：河北XXXX有限公司"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="公告或文件号"  prop="file_number">
            <el-input v-model="addFormInfo.file_number" placeholder="例如：鄂农发[2021]32号"></el-input>
          </el-form-item> -->
          <el-form-item label="是否转基因">
            <el-select v-model="addFormInfo.j_jfzjy" placeholder="请选择">
              <el-option label="否" value="否"></el-option>
              <el-option label="是" value="是"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="特征特性">
            <el-input
              type="textarea"
              autosize="{minRows:2}"
              v-model="addFormInfo.j_tztx"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addFormSubmit">确 定</el-button>
        </div>
      </el-drawer>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ZhongZiclass: [],
      rules: {
        chandi: [{ requir: true, message: "请选择产地名称", trigger: "blur" }],
        cpmc: [{ requir: true, message: "请选择产品名称", trigger: "blur" }],
        scz: [{ requir: true, message: "请输入生产负责人", trigger: "blur" }],
        rq: [{ requir: true, message: "请选择日期", trigger: "blur" }],
      },
      tokenStr: "",
      queryInfo: {
        token: "",
        query: "",
        page: 1,
        pageSize: 8,
      },
      loading: true,
      total: 0,
      // 列表渲染数据
      tableData: [],
      addDialogVisible: false,
      addFormInfo: {
        id: "",
        token: "",
        j_mingcheng: "",
        j_sytgqy: "",
        j_sdsj: "",
        j_sort_path: "",
        j_zhonglei: "",
        j_zhonglei2: "",
        j_scsmc: "",
        j_tztx: "",
        j_jfzjy: "",
        j_xydw: "",
        j_sdbh: "",
      },
      sort: [],

      selectchandi: [],
      selectProduct: [],
    };
  },
  created() {
    this.tokenStr = window.sessionStorage.getItem("token");
    this.getDataList();
  },
  methods: {
    getInfo(row) {
      this.$router.push("/company/detail?id=" + row.id);
    },
    async delInfo(id) {
      const { data: res } = await this.$http.post("delXS", { id: id });
    },

    async getDataList() {
      try {
        this.queryInfo.token = this.tokenStr;
        const { data: res } = await this.$http.post("getXS", this.queryInfo);
        this.tableData = res.data.row;
        this.total = res.data.total;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }

      const { data: res1 } = await this.$http.post("getSort");
      const { data: res2 } = await this.$http.post("getZhongZi");
      this.sort = res1.data;
      this.ZhongZiclass = res2.data;
      this.sort.forEach((item, index) => {
        item["children"].forEach((item2, index2) => {
          // console.log(this.sort[index].children[index2].children)
          this.$delete(this.sort[index].children[index2], "children");
        });
      });
      this.ZhongZiclass.forEach((item3, index3) => {
        this.$delete(this.ZhongZiclass[index3], "children");
      });
    },
    // 分页功能--监听当前页码值 改变事件
    handleCurrentChange(newPage) {
      // console.log("当前页是" + newPage);
      this.queryInfo.page = newPage;
      this.getDataList();
    },
    closedialog() {
      for (var name in this.addFormInfo) {
        this.addFormInfo[name] = "";
      }
    },
    edit(row, event, column) {
      console.log(row);
      let dataRow = JSON.parse(JSON.stringify(row));
      this.addFormInfo = dataRow;
      console.log(this.addFormInfo);
      this.addDialogVisible = true;
    },
    addFormSubmit() {
      this.addFormInfo.token = this.tokenStr;
      this.$http.post("addXS", this.addFormInfo).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.$message.success("添加信息成功");
          this.getDataList();
        }
      });
      this.addDialogVisible = false;
      this.getDataList();
    },
  },
};
</script>

<style scoped lang="less">
.drawer-footer {
  text-align: center;
  width: 100%;
}
.el-breadcrumb {
  height: 30px;
}
.el-pagination {
  margin-top: 10px;
}
.main {
  background-color: #000d4a;
}
// .box-card{
//    background-color: #000d4a;
// }
</style>

